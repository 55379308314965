<template>
  <div>
    <CrudIndex
      v-bind="this.$store.state.stone_model.shop_product_campaign"
      @custom-table-action="$_onCustomTableAction($event)"
    ></CrudIndex>
    <WsDialogBelongsToMany
      ref="WsDialogBelongsToMany"
      :belongsToManyModelName="$store.state.stone_model.shop_product.modelName"
      :belongsToManyFields="$store.state.stone_model.shop_product.fields"
      :belongsToManyShowFields="belongsToMany.showFields"
      :belongsToManyPickShowFields="belongsToMany.pickShowFields"
      :belongsToManyPickFilterSelects="_belongsToManyPickFilterSelects"
      :modelName="$store.state.stone_model.shop_product_campaign.modelName"
      :id="belongsToMany.id"
      :label="belongsToMany.label"
      paramsKey="shop_campaigns"
      updateBtnLabel="管理商品"
      :alert="belongsToMany.alert"
    ></WsDialogBelongsToMany>
  </div>
</template>

<script>
export default {
  methods: {
    $_onCustomTableAction($event) {
      if ($event.emit == "shop_product") {
        this.belongsToMany.id = $event.data.item.id;
        this.belongsToMany.label = `商品促銷 - ${$event.data.item.name}`;
        setTimeout(() => {
          this.$refs.WsDialogBelongsToMany.open();
        }, 0);
      }
    },
    $_checkAlert(value) {
      let check = false;
      value.forEach((valueItem) => {
        if (valueItem.shop_campaigns && valueItem.shop_campaigns.length) {
          const exist = valueItem.shop_campaigns.find((e) => {
            return e.id != this.belongsToMany.id;
          });
          if (exist) {
            check = true;
          }
        }
      });
      return check;
    },
    $_getDescription(value) {
      const productNames = [];
      value.forEach((valueItem) => {
        if (valueItem.shop_campaigns && valueItem.shop_campaigns.length) {
          const exist = valueItem.shop_campaigns.find((e) => {
            return e.id != this.belongsToMany.id;
          });
          if (exist) {
            productNames.push(valueItem.name);
          }
        }
      });
      return `${productNames.join(",")} 已加入其他活動，請取消勾選`;
    },
  },
  data() {
    return {
      belongsToMany: {
        id: null,
        label: null,
        showFields: ["no", "name", "spec"],
        pickShowFields: [
          "no",
          "name",
          "spec",
          "is_active",
          "price",
          "shop_campaigns_names",
        ],
        alert: {
          title: "asdfafd",
          checkAlert: (value) => {
            return this.$_checkAlert(value);
          },
          getDescription: (value) => {
            return this.$_getDescription(value);
          },
        },
      },
    };
  },
  computed: {
    _belongsToManyPickFilterSelects() {
      const _belongsToManyPickFilterSelects = {};
      _belongsToManyPickFilterSelects.featured_classes =
        this.$store.state.stone_model.shop_product.crudSetting.index.filterSelects.featured_classes;
      _belongsToManyPickFilterSelects.shop_classes =
        this.$store.state.stone_model.shop_product.crudSetting.index.filterSelects.shop_classes;
      _belongsToManyPickFilterSelects.shop_subclasses =
        this.$store.state.stone_model.shop_product.crudSetting.index.filterSelects.shop_subclasses;
      _belongsToManyPickFilterSelects.is_active =
        this.$store.state.stone_model.shop_product.crudSetting.index.filterSelects.is_active;
      _belongsToManyPickFilterSelects.shop_campaigns_status = {
        label: "商品活動狀態",
        type: "select",
        items: [
          {
            text: "全部",
            value: "",
          },
          {
            text: "已加入",
            value: `in:${this.belongsToMany.id}`,
          },
          {
            text: "未加入",
            value: `not-in:${this.belongsToMany.id}`,
          },
        ],
        col: 2,
      };
      return _belongsToManyPickFilterSelects;
    },
  },
};
</script>